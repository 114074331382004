<template>
    <b-row>
        <b-col>
            <b-card>
                <b-tabs pills>
                    <b-tab title="Detail" active>
                        <b-row>
                            <b-col md="6" xl="4">
                                <b-form-group label="NIP" label-for="nip">
                                    <b-form-input id="nip" v-model="form.nip" required />
                                </b-form-group>
                            </b-col>
                            <b-col md="6" xl="4">
                                <b-form-group label="NIK" label-for="nik">
                                    <b-form-input id="nik" v-model="form.nik" type="number" onKeyPress="if(this.value.length==16) return false;" required />
                                </b-form-group>
                            </b-col>
                            <b-col md="6" xl="4">
                                <b-form-group label="Name" label-for="name">
                                    <b-form-input id="name" v-model="form.name" required />
                                </b-form-group>
                            </b-col>
                            <b-col md="6" xl="4">
                                <b-form-group label="Birthplace" label-for="birthplace">
                                    <b-form-input id="birthplace" v-model="form.birthplace" required />
                                </b-form-group>
                            </b-col>
                            <b-col md="6" xl="4">
                                <b-form-group label="Birthdate" label-for="birthdate">
                                    <flat-pickr
                                    id="birthdate"
                                    v-model="form.birthdate"
                                    class="form-control"
                                    :config="{ dateFormat: 'd-m-Y'}"
                                    required
                                    />
                                    <!-- <p>Value: '{{ form.birthdate }}'</p> -->
                                </b-form-group>
                            </b-col>
                            <b-col md="6" xl="4">
                                <b-form-group label="Email" label-for="email">
                                    <b-form-input id="email" v-model="form.email" required />
                                </b-form-group>
                            </b-col>
                            <b-col md="6" xl="4">
                                <b-form-group label="Gender" label-for="gender">
                                    <v-select
                                    id="gender"
                                    v-model="selectedGender"
                                    :options="optionsGender"
                                    label="text"
                                    required
                                    />
                                    <!-- Selected: <strong>{{ selectedGender }}</strong> -->
                                </b-form-group>
                            </b-col>
                            <b-col md="6" xl="4">
                                <b-form-group label="Contact" label-for="contact">
                                    <b-form-input id="contact" v-model="form.contact" type="number" onKeyPress="if(this.value.length==13) return false;" required />
                                </b-form-group>
                            </b-col>
                            <b-col md="6" xl="4">
                                <b-form-group label="Organization" label-for="organization">
                                    <v-select
                                    id="organization"
                                    v-model="selectedOrganization"
                                    :options="optionsOrganization"
                                    label="text"
                                    required
                                    />
                                    <!-- Selected: <strong>{{ selectedOrganization }}</strong> -->
                                </b-form-group>
                            </b-col>
                            <b-col md="6" xl="4">
                                <b-form-group label="Division" label-for="division">
                                    <v-select
                                    id="division"
                                    v-model="selectedDivision"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="optionsDivision"
                                    label="text"
                                    required
                                    />
                                    <!-- Selected: <strong>{{ selectedDivision }}</strong> -->
                                </b-form-group>
                            </b-col>
                            <b-col md="6" xl="4">
                                <b-form-group label="Department" label-for="department">
                                    <v-select
                                    id="department"
                                    v-model="selectedDepartment"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="optionsDepartment"
                                    label="text"
                                    required
                                    />
                                    <!-- Selected: <strong>{{ selectedDepartment }}</strong> -->
                                </b-form-group>
                            </b-col>
                            <b-col md="6" xl="4">
                                <b-form-group label="Position" label-for="position">
                                    <v-select
                                    id="position"
                                    v-model="selectedPosition"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="optionsPosition"
                                    label="text"
                                    required
                                    />
                                    <!-- Selected: <strong>{{ selectedPosition }}</strong> -->
                                </b-form-group>
                            </b-col>
                            <b-col md="6" xl="4">
                                <b-form-group label="Manager" label-for="manager">
                                    <v-select
                                    id="manager"
                                    v-model="selectedManager"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="optionsManager"
                                    label="text"
                                    required
                                    />
                                    <!-- Selected: <strong>{{ selectedManager }}</strong> -->
                                </b-form-group>
                            </b-col>
                            <b-col md="6" xl="4">
                                <b-form-group label="Start At" label-for="starts">
                                    <flat-pickr
                                    id="starts"
                                    v-model="form.start_at"
                                    class="form-control"
                                    :config="{ dateFormat: 'd-m-Y'}"
                                    required
                                    />
                                    <!-- Selected: <strong>{{ form.start_at }}</strong> -->
                                </b-form-group>
                            </b-col>
                            <b-col md="6" xl="4">
                                <b-form-group label="Role" label-for="role">
                                    <v-select
                                    id="role"
                                    v-model="selectedRole"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="optionsRole"
                                    label="text"
                                    required
                                    />
                                    <!-- Selected: <strong>{{ selectedRole }}</strong> -->
                                </b-form-group>
                            </b-col>
                            <b-col md="6" xl="4">
                                <b-form-group label="Employee Status" label-for="status">
                                    <v-select
                                    id="status"
                                    v-model="form.selectedStatus"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="form.optionsStatus"
                                    label="text"
                                    required
                                    />
                                    <!-- Selected: <strong>{{ form.selectedStatus }}</strong> -->
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab title="Address">
                        <b-row>
                            <b-col md="12" xl="12">
                                <b-form-group label="Adress" label-for="address">
                                    <b-form-textarea
                                        id="address"
                                        v-model="form2.address"
                                        required
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="4" xl="4">
                                <b-form-group label="Postal Code" label-for="postal_code">
                                    <b-form-input
                                        id="postal_code"
                                        v-model="form2.postal_code"
                                        required
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="4" xl="4">
                                <b-form-group label="Village" label-for="village">
                                    <b-form-input
                                        id="village"
                                        v-model="form2.village"
                                        required
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="4" xl="4">
                                <b-form-group label="District" label-for="district">
                                    <b-form-input
                                        id="district"
                                        v-model="form2.district"
                                        required
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="4" xl="4">
                                <b-form-group label="City" label-for="city">
                                    <b-form-input
                                        id="city"
                                        v-model="form2.city"
                                        required
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="4" xl="4">
                                <b-form-group label="Province" label-for="province">
                                    <b-form-input
                                        id="province"
                                        v-model="form2.province"
                                        required
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab title="Tax">
                        <b-row>
                            <b-col md="6" xl="4">
                                <b-form-group label="Had PPH" label-for="had_pph">
                                    <v-select
                                    id="had_pph"
                                    v-model="form3.selectedPPH"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="form3.optionsPPH"
                                    label="text"
                                    required
                                    />
                                    <!-- Selected: <strong>{{ form3.selectedPPH }}</strong> -->
                                </b-form-group>
                            </b-col>
                            <b-col md="6" xl="4" v-if="event.selectedPPH === 1">
                                <b-form-group label="NPWP" label-for="npwp">
                                    <b-form-input
                                        id="npwp"
                                        v-model="form3.npwp"
                                        required
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="6" xl="4" v-if="event.selectedPPH === 1">
                                <b-form-group label="Had PTKP" label-for="had_ptkp">
                                    <v-select
                                    id="had_ptkp"
                                    v-model="form3.selectedPTKP"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="form3.optionsPTKP"
                                    label="text"
                                    required
                                    />
                                    <!-- Selected: <strong>{{ form3.selectedPTKP }}</strong> -->
                                </b-form-group>
                            </b-col>
                            <b-col md="6" xl="4" v-if="event.selectedPPH === 1 && event.selectedPTKP === 1">
                                <b-form-group label="PTKP" label-for="ptkp">
                                    <v-select
                                    id="ptkp"
                                    v-model="form3.selectedValuePTKP"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="form3.optionsValuePTKP"
                                    label="text"
                                    required
                                    />
                                    <!-- Selected: <strong>{{ form3.selectedValuePTKP }}</strong> -->
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <hr class="my-1" v-if="event.selectedPPH === 1">
                        <span class="font-weight-bolder" v-if="event.selectedPPH === 1">Last Tax</span>
                        <b-row class="mt-1">
                            <b-col md="6" xl="4" v-if="event.selectedPPH === 1">
                                <b-form-group label="Start At" label-for="start_at">
                                    <flat-pickr
                                    id="start_at"
                                    v-model="form3.start_at"
                                    class="form-control"
                                    :config="{ dateFormat: 'd-m-Y'}"
                                    required
                                    />
                                    <!-- <p>Value: '{{ form3.start_at }}'</p> -->
                                </b-form-group>
                            </b-col>
                            <b-col md="6" xl="4" v-if="event.selectedPPH === 1">
                                <b-form-group label="Last Sallary" label-for="last_sallary">
                                    <cleave
                                        id="last_sallary"
                                        placeholder="Last Sallary"
                                        v-model="form3.last_sallary"
                                        class="form-control"
                                        :raw="false"
                                        :options="options.number"
                                        required
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="6" xl="4" v-if="event.selectedPPH === 1">
                                <b-form-group label="Last PPH" label-for="last_pph">
                                    <cleave
                                        id="last_pph"
                                        placeholder="Last PPH"
                                        v-model="form3.last_pph"
                                        class="form-control"
                                        :raw="false"
                                        :options="options.number"
                                        required
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab title="Bank">
                        <b-row>
                            <b-col md="6" xl="4">
                                <b-form-group label="Bank Name" label-for="bank_name">
                                    <b-form-input
                                        id="bank_name"
                                        v-model="form4.bank_name"
                                        required
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="6" xl="4">
                                <b-form-group label="Account No" label-for="account_no">
                                    <b-form-input
                                        id="account_no"
                                        v-model="form4.account_no"
                                        type="number"
                                        required
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="6" xl="4">
                                <b-form-group label="Account Name" label-for="account_name">
                                    <b-form-input
                                        id="account_name"
                                        v-model="form4.account_name"
                                        required
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-tab>
                </b-tabs>
            </b-card>
        </b-col>
        <!-- submit and reset -->
        <b-col md="12">
            <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click="addEmployee()"
            block
            >
            Submit
            </b-button>
        </b-col>
    </b-row>
</template>

<script>
import {
    BFormTextarea, BTab, BTabs, BAlert, BLink, BFormInvalidFeedback, BFormValidFeedback, BFormRadio, BFormDatepicker, BFormSelect, BListGroupItem, BAvatar, BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import draggable from 'vuedraggable'
import Prism from 'vue-prism-component'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import VueThousandSeparator from 'vue-thousand-separator'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'

export default {
    components: {
        Cleave,
        VueThousandSeparator,
        ToastificationContent,
        flatPickr,
        vSelect,
        BFormTextarea,
        BTab,
        BTabs,
        BAlert,
        BLink,
        BFormInvalidFeedback,
        BFormValidFeedback,
        BFormRadio,
        BFormDatepicker,
        BFormSelect,
        BListGroupItem,
        BAvatar,
        BCard,
        BCardText,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BForm,
        BButton,
        draggable,
        Prism
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            form: {
                nip: '',
                nik: null,
                name: '',
                birthplace: '',
                birthdate: null,
                email: '',
                gender: '',
                contact: null,
                start_at: null,
                selectedStatus: null,
                optionsStatus: []
            },
            form2: {
                address: '',
                postal_code: '',
                village: '',
                district: '',
                city: '',
                province: '',
                country: 'Indonesia'
            },
            form3: {
                selectedPPH: null,
                optionsPPH: [
                    { value: null, text: 'Please select'},
                    { value: 1, text: 'Yes'},
                    { value: 0, text: 'No'}
                ],
                npwp: '',
                selectedPTKP: 0,
                optionsPTKP: [
                    { value: null, text: 'Please select'},
                    { value: 1, text: 'Yes'},
                    { value: 0, text: 'No'}
                ],
                selectedValuePTKP: null,
                optionsValuePTKP: [],
                start_at: '',
                last_sallary: '',
                last_pph: ''
            },
            form4: {
                bank_name: '',
                account_no: '',
                account_name: ''
            },
            event: {
                selectedPPH: null,
                selectedPTKP: 0
            },
            user_id: null,
            state: null,
            selectedGender: null,
            optionsGender: [
                { value: null, text: 'Please select gender'},
                { value: 'Pria', text: 'Pria'},
                { value: 'Wanita', text: 'Wanita'}
            ],
            selectedOrganization: null,
            optionsOrganization: [],
            selectedDivision: null,
            optionsDivision: [],
            selectedDepartment: null,
            optionsDepartment: [],
            selectedPosition: null,
            optionsPosition: [],
            selectedManager: '',
            optionsManager: [],
            selectedRole: null,
            optionsRole: [],
            options: {
                number: {
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand',
                },
            },
            errMessage: ''
        }
    },
    watch: {
        selectedOrganization: function (data) {
            this.trigger(data.value)
        },
        selectedDivision: function (data) {
            this.trigger2(data.value)
        },
        'form3.selectedPPH': function (data) {
            this.event1(data.value)
        },
        'form3.selectedPTKP': function (data) {
            this.event2(data.value)
        },
    },
    created() {
        this.getOrganization()
        this.getPosition()
        this.getManager()
        this.getRoles()
        this.getEmployeeStatus()
        this.getPTKP()
    },
    methods: {
        getOrganization() {
            this.$http.get('organizations')
            .then(res => { 
                this.optionsOrganization.push({value: null, text: 'Please select 1 organization'})
                
                var datas = res.data.data
                var i
                for(i=0; i<datas.length; i++) {
                    var $data = {
                        value: datas[i].id,
                        text: datas[i].name
                    }
                    this.optionsOrganization.push($data)
                }
                // console.log(this.optionsOrganization)
            });
        },
        getPosition() {
            this.$http.get('positions')
            .then(res => { 
                this.optionsPosition.push({value: null, text: 'Please select 1 position'})
                
                var datas = res.data.data
                var i
                for(i=0; i<datas.length; i++) {
                    var $data = {
                        value: datas[i].id,
                        text: datas[i].name
                    }
                    this.optionsPosition.push($data)
                }
                // console.log(this.optionsPosition)
            });
        },
        getManager() {
            this.$http.get('employees')
            .then(res => { 
                this.optionsManager.push({value: '', text: 'Optional select manager'})
                
                var datas = res.data.data
                var i
                for(i=0; i<datas.length; i++) {
                    var $data = {
                        value: datas[i].id,
                        text: datas[i].name
                    }
                    this.optionsManager.push($data)
                }
                // console.log(this.optionsManager)
            });
        },
        getRoles() {
            this.$http.get('user/roles')
            .then(res => { 
                this.optionsRole.push({value: null, text: 'Please select role'})
                
                var datas = res.data.data
                var i
                for(i=0; i<datas.length; i++) {
                    var $data = {
                        value: datas[i].id,
                        text: datas[i].name
                    }
                    this.optionsRole.push($data)
                }
                // console.log(this.optionsRole)
            });
        },
        getEmployeeStatus() {
            this.$http.get('employee/status')
            .then(response => { 
                this.form.optionsStatus.push({value: null, text: 'Please select employee status'})
                
                var datas = response.data.data
                var i
                for(i=0; i<datas.length; i++) {
                    var $data = {
                        value: datas[i].id,
                        text: datas[i].name
                    }
                    this.form.optionsStatus.push($data)
                }
                // console.log(this.form.optionsStatus)
            });
        },
        getPTKP() {
            this.$http.get('employee/ptkp')
            .then(response => { 
                this.form3.optionsValuePTKP.push({value: null, text: 'Please select 1 PTKP'})
                
                var datas = response.data.data
                var i
                for(i=0; i<datas.length; i++) {
                    var $data = {
                        value: datas[i].id,
                        text: datas[i].name
                    }
                    this.form3.optionsValuePTKP.push($data)
                }
                // console.log(this.form3.optionsValuePTKP)
            });
        },
        formatRupiah(money) {
            return new Intl.NumberFormat('id-ID',
                { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
            ).format(money);
        },
        event1(val) {
            this.event.selectedPPH = val
            // console.log(this.event.selectedPPH)
        },
        event2(val) {
            this.event.selectedPTKP = val
            // console.log(this.event.selectedPTKP)
        },
        trigger(id) {
            this.$http.get("divisions/all?organization_id="+id)
            .then(res => { 
                this.optionsDivision = [];
                this.selectedDivision = null;
                this.optionsDivision.push({value: null, text: 'Please select 1 division'})
                
                var datas = res.data.data
                var i
                for(i=0; i<datas.length; i++) {
                    var $data = {
                        value: datas[i].id,
                        text: datas[i].name
                    }
                    this.optionsDivision.push($data)
                }
                // console.log(this.optionsDivision)
            });
        },
        trigger2(id) {
            this.$http.get("departments/all?division_id="+id)
            .then(res => { 
                this.optionsDepartment = [];
                this.selectedDepartment = null;
                this.optionsDepartment.push({value: null, text: 'Please select 1 department'})
                
                var datas = res.data.data
                var i
                for(i=0; i<datas.length; i++) {
                    var $data = {
                        value: datas[i].id,
                        text: datas[i].name
                    }
                    this.optionsDepartment.push($data)
                }
                // console.log(this.optionsDepartment)
            });
        },
        validasi1() {
            if(this.form.nip === '') {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'The NIP field is required',
                        variant: 'warning',
                    },
                })
            } else if(this.form.nik === null) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'The NIK field is required',
                        variant: 'warning',
                    },
                })
            } else if(this.form.name === '') {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'The Name field is required',
                        variant: 'warning',
                    },
                })
            } else if(this.form.birthplace === '') {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'The Birthplace field is required',
                        variant: 'warning',
                    },
                })
            } else if(this.form.birthdate === null) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'The Birthdate field is required',
                        variant: 'warning',
                    },
                })
            } else if(this.form.email === '') {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'The Email field is required',
                        variant: 'warning',
                    },
                })
            } else if(this.selectedGender === null) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'The Gender field is required',
                        variant: 'warning',
                    },
                })
            } else if(this.form.contact === null) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'The Phone field is required',
                        variant: 'warning',
                    },
                })
            } else if(this.selectedOrganization === null) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'The Organization field is required',
                        variant: 'warning',
                    },
                })
            } else if(this.selectedDivision === null) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'The Division field is required',
                        variant: 'warning',
                    },
                })
            } else if(this.selectedDepartment === null) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'The Department field is required',
                        variant: 'warning',
                    },
                })
            } else if(this.selectedPosition === null) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'The Position field is required',
                        variant: 'warning',
                    },
                })
            } else if(this.form.start_at === null) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'The Start At field is required',
                        variant: 'warning',
                    },
                })
            } else if(this.selectedRole === null) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'The Role field is required',
                        variant: 'warning',
                    },
                })
            } else if(this.form.selectedStatus === null) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'The Status field is required',
                        variant: 'warning',
                    },
                })
            }
        },
        validasi2() {
            if(this.form3.selectedPPH === null) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'The Had PPH field is required',
                        variant: 'warning',
                    },
                })
            } else if(this.form3.selectedPPH.value === 1 && this.form3.npwp === '') {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'The NPWP field is required',
                        variant: 'warning',
                    },
                })
            } else if(this.form3.selectedPPH.value === 1 && this.form3.selectedPTKP === 0) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'The Had PTKP field is required',
                        variant: 'warning',
                    },
                })
            } else if(this.form3.selectedPTKP.value === 1 && this.form3.selectedValuePTKP === null) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'The PTKP field is required',
                        variant: 'warning',
                    },
                })
            } else if(this.form3.selectedPPH.value === 1 && this.form3.start_at === '') {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'The Start At field is required',
                        variant: 'warning',
                    },
                })
            } else if(this.form3.selectedPPH.value === 1 && this.form3.last_sallary === '') {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'The Last Sallary field is required',
                        variant: 'warning',
                    },
                })
            } else if(this.form3.selectedPPH.value === 1 && this.form3.last_pph === '') {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'The Last PPH field is required',
                        variant: 'warning',
                    },
                })
            } else {
                this.postTax()
            }
        },
        postDetails() {
            this.$http
            .post("employees/add", {
                "nip": this.form.nip,
                "nik": this.form.nik,
                "name": this.form.name,
                "birthplace": this.form.birthplace,
                "birthdate": this.form.birthdate,
                "email": this.form.email,
                "gender": this.selectedGender.value,
                "phone": this.form.contact,
                "organization": this.selectedOrganization.value,
                "division": this.selectedDivision.value,
                "department": this.selectedDepartment.value,
                "position": this.selectedPosition.value,
                "manager_id": this.selectedManager.value,
                "start_at": this.form.start_at,
                "role_id": this.selectedRole.value,
                "status_type": this.form.selectedStatus.value
            })
            .then((response) => {
                this.state = 1
                // console.log(response.data.data)
                this.user_id = response.data.data.id
                setTimeout(this.postAddress(), 1000)
            }).catch((errors) => {
                this.errMessage = errors.response.data.message
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: this.errMessage,
                        variant: 'warning',
                    },
                })
                console.log(errors.response)
            });
        },
        postAddress() {
            this.$http
            .post("employee/"+this.user_id+"/address/add", {
                "address": this.form2.address,
                "postal_code": this.form2.postal_code,
                "village": this.form2.village,
                "district": this.form2.district,
                "city": this.form2.city,
                "province": this.form2.province,
                "country": this.form2.country
            })
            .then((response) => {
                this.state = 2
                // console.log(response.data.data)
                setTimeout(this.validasi2(), 1000)
            }).catch((errors) => {
                this.errMessage = errors.response.data.message
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: this.errMessage,
                        variant: 'warning',
                    },
                })
                console.log(errors.response)
            });
        },
        postTax() {
            if(this.form3.selectedPPH.value === 0) {
                this.$http
                .post("employee/"+this.user_id+"/tax/add", {
                    "had_pph": this.form3.selectedPPH.value
                })
                .then((response) => {
                    this.state = 3
                    // console.log(response.data.data)
                    this.postBank()
                }).catch((errors) => {
                    this.errMessage = errors.response.data.message
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Notification',
                            icon: 'BellIcon',
                            text: this.errMessage,
                            variant: 'warning',
                        },
                    })
                    console.log(errors.response)
                });
            } else {
                var newLastSallary = this.form3.last_sallary
                newLastSallary = newLastSallary.replace(/\,/g,'')
                var newLastPPH = this.form3.last_pph
                newLastPPH = newLastPPH.replace(/\,/g,'')
                this.$http
                .post("employee/"+this.user_id+"/tax/add", {
                    "had_pph": this.form3.selectedPPH.value,
                    "npwp": this.form3.npwp,
                    "had_ptkp": this.form3.selectedPTKP.value,
                    "ptkp": this.form3.selectedValuePTKP.value,
                    "start_at": this.form3.start_at,
                    "last_sallary": newLastSallary,
                    "last_pph": newLastPPH
                })
                .then((response) => {
                    this.state = 3
                    // console.log(response.data.data)
                    this.postBank()
                }).catch((errors) => {
                    this.errMessage = errors.response.data.message
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Notification',
                            icon: 'BellIcon',
                            text: this.errMessage,
                            variant: 'warning',
                        },
                    })
                    console.log(errors.response)
                });
            }
        },
        postBank() {
            this.$http
            .post("employee/"+this.user_id+"/bank/add", {
                "bank_name": this.form4.bank_name,
                "account_no": this.form4.account_no,
                "account_name": this.form4.account_name
            })
            .then((response) => {
                // console.log(response.data.data)
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'Success add Employee',
                        variant: 'success',
                    },
                })
                location.href = "/employees"
            }).catch((errors) => {
                this.errMessage = errors.response.data.message
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: this.errMessage,
                        variant: 'warning',
                    },
                })
                console.log(errors.response)
            });
        },
        addEmployee() {
            if(this.state === null) {
                this.validasi1()
                this.postDetails()
            } else if (this.state === 1) {
                this.postAddress()
            } else if (this.state === 2) {
                this.validasi2()
                // this.postTax()
            } else if (this.state === 3) {
                this.postBank()
            }
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>